/**
 * Copyright (C) Schweizerische Bundesbahnen SBB, 2018.
 */

import {Injectable} from '@angular/core';
import {PartnerApi} from 'cargo-hub-ui-api';
import {PartnerApiInternal} from 'cargo-hub-ui-api-internal';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ZkkPartner} from '../../model/partner-helper';

export enum BeteiligterType {
  absender,
  empfaenger,
  frachtzahlerVersand,
  frachtzahlerEmpfang,
  erfasser,
  endKunde,
  beobachter
}

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  constructor(
    private partnerApi: PartnerApi,
    private partnerApiInternal: PartnerApiInternal
  ) {}

  getPartnerById(partnerId: string): Observable<ZkkPartner> {
    return this.partnerApiInternal.partnerPartneridGet(partnerId) as Observable<ZkkPartner>;
  }

  getPartnersById(partialPartnerId: string): Observable<Array<ZkkPartner>> {
    return this.partnerApi.getStammdatenPartner(partialPartnerId).pipe(
      map(apiPartners => (apiPartners ? (apiPartners as ZkkPartner[]) : [])) // handle 204 gracefully
    );
  }

  getPartnersByName(partialPartnerName: string): Observable<Array<ZkkPartner>> {
    return this.partnerApi.getStammdatenPartner(partialPartnerName).pipe(
      map(apiPartners => (apiPartners ? (apiPartners as ZkkPartner[]) : [])) // handle 204 gracefully
    );
  }

  getAllPartners(): Observable<Array<ZkkPartner>> {
    return this.partnerApi.getStammdatenPartner().pipe(
      map(apiPartners => (apiPartners ? (apiPartners as ZkkPartner[]) : [])) // handle 204 gracefully
    );
  }
}
